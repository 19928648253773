<template>
  <vuestic-widget>
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Packages</span>
        <div class="d-flex justify-content-end align-items-center">
          <router-link v-if="hasAgencyStripeEnabled" class="btn btn-primary btn-sm"
            :to="{ name: 'agency.packages.create' }">Add New Package <i class="fa fa-plus"></i></router-link>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center" v-if="packageFetching">
          <span class="atom-spinner-wrapper">
            <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
          </span>
        </div>
        <div class="col-md-12" v-else>
          <datatable v-bind="this.tableConfig" class="le-datatable" />
        </div>
      </div>
    </div>

    <vuestic-modal :isOpen="isOpenModalDelete" :hideDefaultActions="true" @cancel="closeModalDelete" okText="Delete"
      :cancelShown="false" @ok="onDeletePackage" okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger">Delete Package</span>
      <p>Are you sure you want to delete this Package <strong>{{ selectedPackage ? selectedPackage.name : '' }}</strong>
        ?</p>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalAlert" :cancelShown="false" :focus="true" @close="okModalAlert"
      @ok="okModalAlert" okText="Got It !">
      <span slot="title">Confirmation</span>
      <br />To add plans, you will need to integrate your stripe account first.
      <br />
      <br /><b>Please go to <router-link class="link" :to="{ name: 'agency.settings' }">setting page</router-link> and
        add
        your own stripe credential.</b>
      <br />
      <br />
      <p><i>Please contact <a class="link" href="mailto:support@ringbot.io">support@ringbot.io</a> if you have further
          questions about Stripe Integration.</i></p>
    </vuestic-modal>

  </vuestic-widget>
</template>
  
<script>
import Vue from 'vue';
import components from "../../common/tables/comps";
import Action from './IndexAction'
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      loading: false,
      isOpenModalDelete: false,
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "ID", field: "id", label: "ID", tdClass: "center", sortable: false, visible: true, },
            { title: "Name", field: "name", label: "Name", tdClass: "center", sortable: false, visible: true },
            { title: "Price", field: "price", label: "Price", tdClass: "center", sortable: false, visible: true },
            { title: "SMS Credit", field: "sms_credit", label: "SMS Credit", tdClass: "center", sortable: true, visible: true },
            { title: "Call Credit", field: "voice_credit", label: "Call Credit", tdClass: "center", sortable: true, visible: true },
            { title: 'Is Free', field: 'is_free', sortable: false, visible: true, tdClass: 'center', tdComp: 'TdBoolean', },
            { title: 'Active', field: 'is_active', sortable: true, visible: true, tdClass: 'center', tdComp: "ToggleTd", },
            { title: '', tdComp: Action, visible: 'true', tdClass: 'center', },
          ];
        })(),
        data: [],
        total: 0,
        // selection: [],  //if this is present the row selector shows up..
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue()
        }
      },
      selectedPackage: null,
      isOpenModalAlert: false,
    };
  },

  created() {
    this.tableConfig.xprops.eventbus.$on('edit', row => {
      this.$router.push({ name: 'agency.packages.edit', params: { id: row.id } })
    })

    this.tableConfig.xprops.eventbus.$on('delete', row => {
      this.selectedPackage = row
      this.isOpenModalDelete = true
    })

    this.tableConfig.xprops.eventbus.$on('toggleActive', row => {
      this.toggleActive(row)
    });

    const offset = (this.packagePage - 1 >= 0 ? this.packagePage - 1 : 0) * this.packagePerPage;
    this.tableConfig.query = {
      offset,
      limit: this.packagePerPage,
    }
  },

  watch: {
    packages: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.data = newValue;
        }
      },
      deep: true,
    },
    packagesTotal: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.total = newValue;
        }
      },
      deep: true,
    },
    'tableConfig.query': {
      handler() {
        this.loadPackages();
      },
      deep: true
    }
  },

  computed: {
    ...mapState('package', {
      packageFetching: 'fetching',
      packageDidFetch: 'didFetch',
      packageProcessing: 'processing',
      packagePage: 'page',
      packagePerPage: 'perPage',
      packageFilter: 'filter',
      packages: 'data',
      packagesTotal: 'total'
    }),

    user() {
      return this.$store.state.auth.user;
    },

    hasAgencyStripeEnabled() {
      return this.$store.getters['auth/hasAgencyStripeEnabled']
    },
  },
  mounted() {
  },
  methods: {
    toggleActive(record) {
      this.$store.dispatch('package/toggleActive', record)
        .then(res => {
        })
        .catch(() => { })
    },

    loadPackages() {
      const { query } = this.tableConfig;
      const page = query.offset ? Math.floor(query.offset / query.limit) + 1 : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort
      }

      this.$store.dispatch('package/fetch', param).then((res) => {
        if (!res.stripe_enabled)
          this.isOpenModalAlert = true;
      })
    },

    okModalAlert() {
      this.$router.push({ name: 'agency.settings' })
    },

    closeModalDelete() {
      this.isOpenModalDelete = false
    },

    onDeletePackage() {
      this.$store
        .dispatch('package/delete', this.selectedPackage.id)
        .then(() => {
          this.isOpenModalDelete = false;
          this.loadPackages();
          this.selectedPackage = null;
        })
        .catch((err) => {
        })
    },
  },

};
</script>
  
<style lang="scss" scoped>

</style>
  